import { forwardRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { entities } from 'my-core/my-utils';
import { studentSignupPath } from 'my-core/routes';

import ButtonXL from 'my-elements/ButtonXL';
import Container from 'my-layout/Container';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import curriculumSrc from 'images/product-shots/curriculum.png';
import lessonSrc from 'images/product-shots/lesson.png';
import quizQuestionSrc from 'images/product-shots/quiz_question.png';
import biancaImg from 'images/testimonials/bianca.jpg';
import emilyImg from 'images/testimonials/emily.jpg';
import ryconImg from 'images/testimonials/rycon.jpg';

const DATA = [
  {
    header: `Follow Along With Your${entities.noBreakSpace}Course`,
    subheader: `No more hunting for information online. Our expert tutors curate courses for you, based on your syllabus and textbook to ensure that you’re learning exactly what will be on your${entities.noBreakSpace}exams.`,
    testimonial: {
      avatarSrc: ryconImg,
      quote: `Wizeprep’s course-specific videos and practice questions helped me to get through University Calculus and I ended up getting an${entities.noBreakSpace}A-.`,
      author: 'Rycon',
    },
    asset: {
      alt: 'curriculum',
      src: curriculumSrc,
      width: 436,
      height: 518,
    },
  },
  {
    header: `Quickly Understand Complex${entities.noBreakSpace}Concepts`,
    subheader: `With bite-sized videos and comprehensive, easy-to-understand notes that cover all of the concepts in your course, you can fit your study session in whenever you have${entities.noBreakSpace}time.`,
    testimonial: {
      avatarSrc: biancaImg,
      quote: `Wizeprep explains concepts in a very concise way that is really easy to${entities.noBreakSpace}understand.`,
      author: 'Bianca',
    },
    asset: {
      alt: 'course lesson with video',
      src: lessonSrc,
      width: 459,
      height: 360,
    },
  },
  {
    header: `Be Prepared With Exam-Like${entities.noBreakSpace}Practice`,
    subheader: `Learn how to solve tough problems and get exam ready with exam-like practice questions and step-by-step${entities.noBreakSpace}solutions.`,
    testimonial: {
      avatarSrc: emilyImg,
      quote: `The extra practice questions truly help me understand the material instead of just memorizing${entities.noBreakSpace}it.`,
      author: 'Emily',
    },
    asset: {
      alt: 'multiple-choice question',
      src: quizQuestionSrc,
      width: 450,
      height: 407,
    },
  },
];

const classes = {
  root: ({ breakpoints, palette, spacing }) => ({
    backgroundColor: palette.background.dark,
    color: palette.common.white,
    padding: spacing(5, 0),

    [breakpoints.up('sm')]: {
      padding: spacing(10, 0),
    },
    [breakpoints.up('md')]: {
      padding: spacing(20, 0),
    },
  }),
  container: ({ breakpoints, spacing }) => ({
    [breakpoints.up('sm')]: {
      padding: spacing(0, 5),
    },
    [breakpoints.up('md')]: {
      display: 'flex',
      gap: spacing(5),
    },
  }),
  left: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      flexShrink: 0,
      maxWidth: 550,
    },
  }),
  right: ({ breakpoints }) => ({
    flexGrow: 1,
    position: 'relative',

    [breakpoints.down('md')]: {
      display: 'none',
      visiblity: 'hidden',
    },
  }),

  content: ({ breakpoints, spacing }) => ({
    '& + &': { marginTop: spacing(20) },

    [breakpoints.up('md')]: {
      '& + &': { marginTop: spacing(30) },
    },
  }),
  header: ({ spacing }) => ({
    marginBottom: spacing(2),
  }),

  testimonial: ({ breakpoints, spacing }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      'quote quote'
      'avatar author'
    `,
    gap: spacing(1.5),
    alignItems: 'center',
    textAlign: 'left',

    [breakpoints.only('sm')]: {
      maxWidth: 500,
      margin: 'auto',
    },
    [breakpoints.up('md')]: {
      maxWidth: 420,
      marginTop: spacing(10),
      gridTemplateAreas: `
        'avatar quote'
        'avatar author'
      `,
    },
  }),
  testimonialQuote: { gridArea: 'quote' },
  testimonialAuthor: { gridArea: 'author' },
  testimonialAvatar: ({ breakpoints }) => ({
    gridArea: 'avatar',
    width: 33,
    height: 33,

    [breakpoints.up('md')]: {
      width: 80,
      height: 80,
    },
  }),

  imageWrapper: {
    '& > img': {
      maxWidth: 440,
      display: 'inline-block',
      width: '100%',
      height: 'auto',
    },
  },
  imageWrapperSm: ({ breakpoints, spacing }) => ({
    margin: spacing(5, 0),
    textAlign: 'center',

    [breakpoints.up('md')]: {
      display: 'none',
      visiblity: 'hidden',
    },
  }),
  imageWrapperLg: [
    ({ constants, transitions }) => ({
      position: 'sticky',
      top: 160 + constants.APPBAR_OFFSET,
      opacity: 0,
      transition: transitions.create('opacity', {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeOut,
      }),

      '&.active': {
        opacity: 1,
        transition: transitions.create('opacity', {
          duration: transitions.duration.shortest,
          delay: transitions.duration.shortest,
          easing: transitions.easing.easeIn,
        }),
      },

      '& + &': { marginTop: '-100%' },
    }),
    ({ constants }) => ({ top: `max(16px + ${constants.APPBAR_OFFSET}px, calc(50% - 250px))` }), // override top property if max fn exists
  ],
};

export default forwardRef(function LandingPageValueProposition({ ctaPath, headerComponent = 'h2' }, ref) {
  const [activeSection, setActiveSection] = useState(0);

  return (
    <div css={classes.root} ref={ref}>
      {/* <div css={{position: 'fixed', top: '40.9%', bottom: '59%', left: 0, right: 0, border: `1px dotted red`}} /> */}
      <Container css={classes.container}>
        <div css={classes.left}>
          {DATA.map(({ asset, header, subheader, testimonial }, index) => (
            <InView
              key={header}
              css={classes.content}
              onChange={inView => inView && setActiveSection(index)}
              rootMargin="-40.9% 0px -59% 0px"
            >
              <Typography
                color="inherit"
                component={headerComponent}
                css={classes.header}
                sx={{ typography: { xs: 'h5', sm: 'h4' } }}
              >
                {header}
              </Typography>
              <Typography color="inherit" variant="body1">
                {subheader}
              </Typography>
              <div css={[classes.imageWrapper, classes.imageWrapperSm]}>
                <img {...asset} />
              </div>
              <article css={classes.testimonial}>
                <Typography color="inherit" css={classes.testimonialQuote} variant="body2">
                  {testimonial.quote}
                </Typography>
                <Typography color="inherit" css={classes.testimonialAuthor} variant="body2">
                  - {testimonial.author}, Undergraduate Student
                </Typography>
                <Avatar alt={testimonial.author} css={classes.testimonialAvatar} src={testimonial.avatarSrc} />
              </article>
            </InView>
          ))}
        </div>
        <div css={classes.right}>
          {DATA.map(({ asset }, index) => (
            <div
              key={asset.alt}
              className={index === activeSection ? 'active' : undefined}
              css={[classes.imageWrapper, classes.imageWrapperLg]}
            >
              <img {...asset} />
            </div>
          ))}
        </div>
      </Container>
      <Box sx={{ pt: { xs: 10, sm: 15, lg: 20 }, px: { xs: 2, sm: 5 }, textAlign: 'center' }}>
        <Typography color="white" component="h3" sx={{ mb: 5 }} variant="h5">
          Unlimited Courses, One&nbsp;Subscription
        </Typography>
        <ButtonXL
          component={Link}
          onClick={() => landingPageCTAClicked('value_props_section', 'start_studying')}
          to={ctaPath || studentSignupPath()}
          variant="contained"
        >
          Start Studying for Free
        </ButtonXL>
        <Typography sx={{ color: 'text.secondary', mt: 1.5 }}>No credit card required</Typography>
      </Box>
    </div>
  );
});
